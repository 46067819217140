import React from "react"

import { Link } from "gatsby"

const Pagination = ({ urlPrefix, pageContext }) => {
	const { currentPage, numPages } = pageContext

	const isFirstPage = currentPage === 1
	const isLastPage = currentPage === numPages

	const prevPage = currentPage - 1 === 1 ? `${urlPrefix}` : `${urlPrefix}/page/${currentPage - 1}`
	const nextPage = `${urlPrefix}/page/${currentPage + 1}`

	const clamp = (num, min, max) => Math.min(Math.max(num, min), max)

	const range = (start, end, step = 1) => {
		let output = []
		if (typeof end === "undefined") {
			end = start
			start = 0
		}
		for (let i = start; i < end; i += step) {
			output.push(i)
		}
		return output
	}

	return (
		<>
			<div className="flex flex-row gap-x-24">
				{!isFirstPage && (
					<span className="text-silversand flex items-center" title="Previous page">
						<Link to={`${prevPage}`}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-[14px] h-[12px] fill-current"
								viewBox="0 0 14 12">
								<path d="m0 6 5.707 5.707 1.414-1.414L3.829 7H14V5H3.829l3.292-3.293L5.707.293z" />
							</svg>
						</Link>
					</span>
				)}

				{range(
					clamp(currentPage - 3, 0, numPages),
					clamp(currentPage + 2, 0, numPages),
				).map(page => {
					return (
						<Link
							key={`pagination-link-${page}`}
							to={page === 0 ? urlPrefix : `${urlPrefix}/page/${page + 1}`}
							className={`w-[34px] h-[34px] rounded-default text-sm font-bold flex justify-center items-center hover:bg-pictonblue hover:text-white ${
								page + 1 === currentPage
									? "bg-tealblue text-white"
									: "bg-porcelain text-silversand"
							}`}>
							{page + 1}
						</Link>
					)
				})}

				{/* {Array.from({ length: numPages }).map((_, index) => {
					return (
						<Link
							key={`pagination-link-${index}`}
							to={index === 0 ? urlPrefix : `${urlPrefix}/page/${index + 1}`}
							className={`w-[34px] h-[34px] rounded-default text-sm font-bold flex justify-center items-center ${
								index + 1 === currentPage
									? "bg-tealblue text-white"
									: "bg-porcelain text-silversand"
							}`}>
							{index + 1}
						</Link>
					)
				})} */}

				{!isLastPage && (
					<span className="text-silversand flex items-center" title="Next page">
						<Link
							to={`${nextPage}`}
							disabled={currentPage + 1 > numPages ? "true" : null}>
							<svg
								xmlns="http://www.w3.org/2000/svg"
								className="w-[14px] h-[12px] fill-current"
								viewBox="0 0 14 12">
								<path d="m14 5.707-5.707 5.707L6.879 10l3.292-3.293H0v-2h10.171L6.879 1.414 8.293 0z" />
							</svg>
						</Link>
					</span>
				)}
			</div>
		</>
	)
}

export default Pagination
